import React from 'react';
import Divider from './Divider';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const PrivacyPolicyEN = () => {
  const {t} = useTranslation();
  return(
    <>
      <section className="text-gray-600 bg-gray-100 body-font pt-10">
        <div className="my-cont doc-para">
          <Divider title={t('sub.title')} />
          <p><strong>IOHUB</strong> collects the information of the user sent to us. We also collect information through user-visited pages, information provided through surveys and registrations, API. This information may contain your data including: name, address, phone number, email address, etc. We are not permitted to disclose any personal information without permission in writing. However, some information collected from you and about you will be used to assist in our service provision to you. The information we collect is not shared with you, sold or leased, except in the following cases:</p>
          <ul>
            <li>IOHUB may share personal information to investigate or prevent illegal activities, suspected fraud, situations involving the life-safety threats of any person as required by law.</li>
            <li>IOHUB cooperates with other companies that represent us on some works and may need to share your information with them in order to provide products and services to you. IOHUB may also share your information to provide the product or service that you request or when we get your permission.</li>
            <li>We will transfer your information if we are acquired or merged with another company. In this case, IOHUB will notify you by email or by a prominent notice on our website before information about you is transferred and governed by another Privacy Policy.</li>
            <li>This summary is provided to you for your benefit and is not legally binding. Please comprehensively read the Terms for full understanding.</li>
          </ul>
          <p></p>
          <p>By signing up for the Service you are agreeing to be bound by the Privacy Policy. Any new features or tools which are added to the current Service shall also be subject to the Privacy Policy. You can review the current version of the Privacy Policy at any time here: <a href="https://iohub.tech/privacypolicy">https://iohub.tech/privacypolicy</a>.</p>
          <p>You must read, agree with and accept all of the terms and conditions contained in this Privacy Policy agreement and our Terms of Service before you may use the Service.</p>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="my-cont doc-para">
					<div className="doc-para-item">
            <h2>1. Which type of information we collect?</h2>
            <ol>
              <li><strong>IOHUB Customers (Customers)</strong>: Name, address, company name, identification, publicly available social media profile information, e-mail, phone number, IT information (IP addresses, usage data, cookie data, browser data); financial information (payment information). We use a third-party service provider to process your credit card transactions. These third parties are governed by their own agreements.</li>
              <li><strong>Purchasing a paid service package</strong>. When you sign up for a paid subscription for the Services, we collect information to process your payment and create your customer account. This information includes name, email address, physical address, telephone number, and company name where applicable.</li>
              <li><strong>Customer-generated content</strong>. Our products and services often give you the option to provide feedback, such as suggestions, compliments or problems encountered. We invite you to provide such feedback as well as to participate with comments on our blog and community page. If you choose to post a comment, your user name, city, and any other information that you choose to post will be visible to the public. We are not responsible for the privacy of any information that you choose to post to the Site, including in our blogs, or for the accuracy of any information contained in those postings. Any information that you disclose becomes public information. We cannot prevent such information from being used in a manner that may violate this Privacy Policy, the law or your personal privacy.</li>
              <li><strong>Data collected for and by our Customers</strong>. As you use our Services, you may import into our system personal information you have collected from your users, customers, prospective customers, and social media and messaging platform contacts (collectively “Subscribers”) or other individuals. We have no direct relationship with your Subscribers or any person other than you, and for that reason, you are responsible for making sure you have the appropriate permission and legal basis for us to collect and process information about those individuals. As part of our Services, we may use and incorporate features information you have provided, we have collected from you, or we have collected information about Subscribers. If you are a Subscriber and no longer want to be contacted by one of our users, please unsubscribe directly from that user’s bot or contact the user directly to update or delete your data.</li>
              <li><strong>Information collected automatically</strong>. Our servers may automatically record certain information about how you use our Site (we refer to this information as “Log Data“), including both Customers and casual visitors. Log Data may include information such as a user’s Internet Protocol (IP) address, device and browser type, operating system, the pages or features of our Site to which a user browsed and the time spent on those pages or features, the frequency with which the Site is used by a user, search terms, the links on our Site that a user clicked on or used, and other statistics. IOHUB and our partners may use various technologies to collect and store information when you use our Services, and this may include using cookies and similar tracking technologies on our Site, such as pixels and web beacons. Users can control the use of cookies at the individual browser level. Alone or in combination with other information, log files and information collected by means of cookies may constitute personal information.</li>
              <li><strong>Sensitive personal information</strong>. Subject to the following paragraph, we ask that you not send or disclose to us any sensitive personal information (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or union membership) on or through the Services or otherwise.
                <p>If you send or disclose any sensitive personal information to us (such as when you submit Customer-generated content to the Site), you must consent to our processing and use of such sensitive personal information in accordance with this Privacy Policy. If you do not consent to our processing and use of such sensitive personal information, you must not provide it. You may use your data protection rights to object to or restrict processing of this sensitive personal information, or to delete such information, as detailed below in Clause 4.</p>
              </li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>2. What will we do with your information?</h2>
            <ol>
              <li>The term "Personal Information" used herein is defined as any information that can be used to identify, communicate with or locate the person to whom it is related. The personal information we collect is confidential to the individual, and may be modified from time to time.</li>
              <li>When you sign up for IOHUB, we ask you to provide your name, company name, email address, billing address, and phone number.</li>
              <li>IOHUB uses the information we collect for the following general purposes: products and services provided, communication with you, payment, identification and authentication, service improvement, contact, research and fraud prevention.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>3. Security</h2>
            <p>The security of your personal information is very important to us. When you submit sensitive information, such as password into our application form, we encrypt the transmission of information using SSL - Secure Sockets Layer encryption technology. No method of transmission through the Internet, or electronic storage method, is 100% secure. Therefore, while we strive to use secure encryption media to protect your personal information, we cannot guarantee absolute security. If you have any questions about security on our website, you can contact us via email.</p>
					</div>
					<div className="doc-para-item">
            <h2>4. Disclosure of data</h2>
            <ol>
              <li><strong>IOHUB</strong> may use third-party service providers to provide somes services to you, and we may share personal information with those service providers. We require any company with whom we may share personal information to protect data consistent with this policy and to restrict the use of such personal information to perform services to IOHUB.</li>
              <li>IOHUB may disclose personal information in exceptional cases, such as following a court order asking us to do so or when your actions violate the Terms of Service.</li>
              <li>We do not sell or provide personal information to other companies to market our products or services.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>5. Customer data storage</h2>
            <ol>
              <li><strong>IOHUB</strong> owns the data storage, databases, and all rights to IOHUB’s application. However, we do not require the use of the rights of your data. You retain all rights to your data and we will never contact your customers directly, or use your data for our business advantage or compete with you or the market to get your customers.</li>
              <li>If you close your account, we still may retain certain information relating to your account to analyze and keep integrity records, as well as to avoid fraud, collect any fees payable, enforce our terms and conditions, our website or our users, or take other actions as permitted by law.</li>
              <li>In addition, you will be provided with several rights on your own data protection choices:
                <ul>
                  <li>If you wish to access your personal information that IOHUB collects, you can do so at any time by contacting us</li>
                  <li>You can review, update, change the personal information in your registration profile by logging into your account or contact us to accomplish the foregoing.
                    <p>Due to the complexity of the technology involved, we cannot guarantee full deletion or cannot recover your account information.</p>
                    <p>If you have any requests for data deletion on our database, you can email us, IOHUB will process your request within 30 days of confirmation by email.</p>
                  </li>
                  <li>You can object to processing of your personal information, ask us to restrict processing of your personal information or request portability of your personal information where it is technically possible. Again, you can exercise these rights by contacting us.</li>
                </ul>
              </li>
              <li>If certain information has been provided to third parties as described in this Privacy Policy, the retention of such information will be subjected to those third-party policies.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>6. Cookies</h2>
            <p>Cookies are a small amount of data, which can include a unique anonymous identity. Cookies are sent to your browser from a website and stored on your computer's hard drive. Each computer that can access our website is assigned a different cookie.</p>
					</div>
					<div className="doc-para-item">
            <h2>7. Children's privacy</h2>
            <p>This is a general audience site and does not offer services directed to children. Should a child whom we know to be under 18 send personal information to us, we will use that information only to respond directly to that child to inform him or her that we must have parental consent before receiving his or her personal information.</p>
					</div>
					<div className="doc-para-item">
            <h2>8. The EU general data protection regulation</h2>
            <p><strong>IOHUB</strong> operates mainly in Vietnam. Currently Vietnam has not been recognised by the European Commission as providing adequate protection. We are currently working with a team of experts dedicated to making any required adjustments to our products, services and documentation, to ensure compliance with the European law (where applicable). Therefore, we will endeavour to reinforce your privacy protection and your rights with the reference to the EU General Data Protection Regulation (EU-GDPR). More information about this is available at <u>https://www.gdpreu.org</u>.</p>
					</div>
					<div className="doc-para-item">
            <h2>9. Revise Privacy Policy</h2>
            <p>We have the right to change this Privacy Policy at any time, so consider it regularly. If we make a significant change to this policy, we will notify you here or by a notice on our homepage so that you are aware of what information we collect, how we use it, and under what circumstances we will disclose it, if any.</p>
					</div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicyEN;
