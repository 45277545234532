import React from 'react';
import Divider from './Divider';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const PrivacyPolicyVI = () => {
  const {t} = useTranslation();
  return(
    <>
      <section className="text-gray-600 bg-gray-100 body-font pt-10">
        <div className="my-cont doc-para">
          <Divider title={t('sub.title')} />
          <p><strong>IOHUB</strong> thu thập thông tin của người dùng gửi cho chúng tôi. Chúng tôi cũng thu thập thông tin thông qua những trang người dùng truy cập, thông tin được cung cấp thông qua việc khảo sát và đăng ký, API. Những thông tin này có thể chứa dữ liệu cá nhân của bạn bao gồm: tên, địa chỉ, số điện thoại, địa chỉ email, vv... Chúng tôi không được phép tiết lộ thông tin cá nhân mà không có sự cho phép bằng văn bản. Tuy nhiên, một số thông tin thu thập từ bạn và về bạn sẽ được sử dụng để hỗ trợ việc cung cấp dịch vụ của chúng tôi với bạn. Những thông tin chúng tôi thu thập không được chia sẻ với bạn, bán hoặc cho thuê, ngoại trừ một số trường hợp sau đây:</p>	
          <ul>
            <li>IOHUB có thể chia sẻ thông tin cá nhân để điều tra hoặc ngăn chặn các hoạt động bất hợp pháp, nghi ngờ gian lận, các tình huống liên quan đến các mối đe dọa sự an toàn tính mạng của bất kỳ người nào theo yêu cầu của luật pháp.</li>
            <li>IOHUB hợp tác với các công ty khác đại diện chúng tôi ở một số nhiệm vụ và có thể cần phải chia sẻ thông tin của bạn với họ để cung cấp các sản phẩm và dịch vụ cho bạn. IOHUB cũng có thể chia sẻ thông tin của bạn để cung cấp sản phẩm hoặc dịch vụ mà bạn yêu cầu hoặc khi chúng tôi có được sự cho phép của bạn.</li>
            <li>Chúng tôi sẽ chuyển thông tin về bạn nếu chúng tôi được mua lại hoặc sáp nhập với công ty khác. Trong trường hợp này, chúng tôi sẽ thông báo cho bạn bằng email hoặc bằng cách thông báo nổi bật trên trang web của chúng tôi trước khi thông tin về bạn được chuyển giao và trở thành đối tượng của một Chính sách Bảo mật khác.</li>
            <li>Bảng tóm tắt này được cung cấp cho bạn vì lợi ích của bạn và không ràng buộc pháp lý. Xin vui lòng đọc Điều khoản một cách toàn diện để nắm rõ.</li>
          </ul>
          <p></p>
          <p>Bằng cách đăng ký Dịch vụ, bạn đồng ý bị ràng buộc bởi Chính sách Bảo mật. Bất kỳ tính năng hoặc công cụ mới nào được thêm vào Dịch vụ hiện tại cũng phải tuân theo chính sách bảo mật. Bạn có thể xem lại phiên bản hiện tại của Chính sách Bảo mật bất cứ lúc nào tại đây: <a href="https://iohub.tech/vi/privacypolicy">https://iohub.tech/vi/privacypolicy</a>.</p>
          <p>Bạn phải đọc, đồng ý và chấp nhận tất cả các điều khoản và điều kiện có trong Chính sách Bảo mật này và Điều khoản Dịch vụ của IOHUB trước khi bạn có thể sử dụng Dịch vụ.</p>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="my-cont doc-para">
					<div className="doc-para-item">
            <h2>1. Chúng tôi thu thập những thông tin nào?</h2>
            <ol>
              <li><strong>Khách hàng của IOHUB (Khách hàng):</strong> Tên, địa chỉ, tên công ty, nhận dạng, thông tin hồ sơ mạng xã hội công khai có sẵn, e-mail, số điện thoại, thông tin IT (địa chỉ IP, dữ liệu sử dụng, dữ liệu cookie, dữ liệu trình duyệt); thông tin tài chính (thông tin thanh toán). Chúng tôi sử dụng nhà cung cấp dịch vụ bên thứ ba để xử lý các giao dịch thẻ tín dụng của bạn. Các bên thứ ba này được chi phối bởi các thỏa thuận của riêng họ.</li>
              <li><strong>Mua gói giải pháp trả phí</strong>. Khi bạn đăng ký gói giải pháp trả phí, chúng tôi sẽ thu thập thông tin để xử lý thanh toán của bạn và tạo tài khoản khách hàng của bạn. Thông tin này bao gồm tên, địa chỉ email, địa chỉ thực, số điện thoại và tên công ty nếu có.</li>
              <li><strong>Nội dung do khách hàng tạo</strong>. Các sản phẩm và dịch vụ của chúng tôi thường cung cấp cho bạn tùy chọn cung cấp phản hồi, chẳng hạn như đề xuất, lời khen hoặc vấn đề gặp phải. Chúng tôi mời bạn cung cấp phản hồi như vậy cũng như tham gia với các bình luận trên blog và trang cộng đồng của chúng tôi. Nếu bạn chọn đăng nhận xét, tên người dùng, thành phố và bất kỳ thông tin nào khác mà bạn chọn đăng sẽ hiển thị công khai. Chúng tôi không chịu trách nhiệm về quyền riêng tư của bất kỳ thông tin nào bạn chọn đăng lên Trang web, bao gồm cả trong blog của chúng tôi hoặc về tính chính xác của bất kỳ thông tin nào có trong các bài đăng đó. Bất kỳ thông tin nào bạn tiết lộ sẽ trở thành thông tin công khai. Chúng tôi không thể ngăn thông tin đó được sử dụng theo cách có thể vi phạm Chính sách Bảo mật này, luật pháp hoặc quyền riêng tư cá nhân của bạn.</li>
              <li><strong>Dữ liệu được thu thập cho và bởi Khách hàng của chúng tôi</strong>. Khi bạn sử dụng Dịch vụ của chúng tôi, bạn có thể nhập vào hệ thống của chúng tôi những thông tin cá nhân mà bạn đã thu thập từ người dùng, khách hàng và khách hàng tiềm năng của bạn và thông tin liên hệ trên nền tảng nhắn tin và phương tiện truyền thông xã hội (gọi chung là "Người đăng ký") hoặc các cá nhân khác. Chúng tôi không có mối quan hệ trực tiếp với Người đăng ký của bạn hoặc bất kỳ người nào khác ngoài bạn và vì lý do đó, bạn có trách nhiệm đảm bảo bạn có sự cho phép thích hợp và cơ sở pháp lý để chúng tôi thu thập và xử lý thông tin về những cá nhân đó. Là một phần của Dịch vụ, chúng tôi có thể sử dụng và kết hợp vào các tính năng thông tin bạn đã cung cấp, hoặc chúng tôi đã thu thập từ bạn hoặc thu thập về Người đăng ký. Nếu bạn là Người đăng ký và không muốn được một trong những người dùng của chúng tôi liên hệ, vui lòng hủy đăng ký trực tiếp khỏi bot của người dùng đó hoặc liên hệ trực tiếp với người dùng đó để cập nhật hoặc xóa dữ liệu của bạn.</li>
              <li><strong>Thông tin được thu thập tự động</strong>. Máy chủ của chúng tôi có thể tự động ghi lại một số thông tin nhất định về cách bạn sử dụng Trang web của chúng tôi (chúng tôi gọi thông tin này là "Dữ liệu nhật ký"), bao gồm cả Khách hàng và khách truy cập thông thường. Dữ liệu Nhật ký có thể bao gồm thông tin như địa chỉ IP, loại thiết bị và trình duyệt, hệ điều hành, các trang hoặc tính năng của Trang web của chúng tôi mà người dùng sử dụng và thời gian dành cho các trang hoặc tính năng đó, tần suất người dùng sử dụng Trang web, cụm từ tìm kiếm, các liên kết trên Trang web của chúng tôi mà người dùng nhấp vào hoặc sử dụng và các số liệu thống kê khác. IOHUB và các đối tác của chúng tôi có thể sử dụng các công nghệ khác nhau để thu thập và lưu trữ thông tin khi bạn sử dụng Dịch vụ và điều này có thể bao gồm việc sử dụng cookie và các công nghệ theo dõi tương tự trên Trang web của chúng tôi, chẳng hạn như pixel và đèn hiệu web. Người dùng có thể kiểm soát việc sử dụng cookie ở cấp độ trình duyệt riêng lẻ. Riêng lẻ hoặc kết hợp với các thông tin khác, các tệp nhật ký và thông tin được thu thập bằng cookie có thể cấu thành thông tin cá nhân.</li>
              <li><strong>Thông tin cá nhân nhạy cảm</strong>. Theo bên dưới, chúng tôi yêu cầu bạn không gửi hoặc tiết lộ cho chúng tôi bất kỳ thông tin cá nhân nhạy cảm nào (ví dụ: số an sinh xã hội, thông tin liên quan đến nguồn gốc chủng tộc hoặc dân tộc, ý kiến chính trị, tôn giáo hoặc tín ngưỡng, sức khỏe, sinh trắc học hoặc đặc điểm di truyền khác, lý lịch tội phạm hoặc thành viên công đoàn) trên hoặc thông qua Dịch vụ hoặc bằng cách khác.
                <p>Nếu bạn gửi hoặc tiết lộ bất kỳ thông tin cá nhân nhạy cảm nào cho chúng tôi (chẳng hạn như khi bạn gửi nội dung do Khách hàng tạo lên Trang web), bạn phải đồng ý với việc chúng tôi xử lý và sử dụng thông tin cá nhân nhạy cảm đó theo Chính sách Bảo mật này. Nếu bạn không đồng ý với việc xử lý và sử dụng thông tin cá nhân nhạy cảm của chúng tôi, bạn không được cung cấp thông tin đó. Bạn có thể sử dụng quyền bảo vệ dữ liệu của mình để phản đối hoặc hạn chế việc xử lý thông tin cá nhân nhạy cảm này hoặc xóa thông tin đó, như được nêu chi tiết bên dưới điều 4.</p>
              </li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>2. Chúng tôi sẽ làm gì với thông tin của bạn? </h2>
            <ol>
              <li>Thuật ngữ "Thông tin cá nhân" được sử dụng ở đây được định nghĩa là bất kỳ thông tin nào có thể được sử dụng để nhận dạng, liên lạc hoặc xác định vị trí người mà thông tin đó liên quan. Các thông tin cá nhân mà chúng tôi thu thập đều mang tính bảo mật cho cá nhân, và có thể được sửa đổi theo thời gian.</li>
              <li>Khi bạn đăng ký IOHUB chúng tôi hỏi tên của bạn, tên công ty, địa chỉ email, địa chỉ thanh toán, và số điện thoại.</li>
              <li>IOHUB sử dụng thông tin chúng tôi thu thập cho các mục đích chung sau đây: cung cấp sản phẩm và dịch vụ, trao đổi với bạn, thanh toán, xác định và xác thực, cải thiện dịch vụ, liên hệ, nghiên cứu và chống gian lận.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>3. Sự bảo mật</h2>
            <p>Bảo mật thông tin cá nhân của bạn là rất quan trọng với chúng tôi. Khi bạn nhập thông tin nhạy cảm, như mật khẩu vào mẫu đăng ký của chúng tôi, chúng tôi mã hóa việc truyền tải thông tin bằng cách sử dụng công nghệ mã hóa an toàn SSL - Secure Sockets Layer. Không có phương pháp truyền qua Internet, hoặc phương pháp lưu trữ điện tử, là 100% an toàn. Vì vậy, trong khi chúng tôi cố gắng sử dụng phương tiện mã hóa an toàn để bảo vệ thông tin cá nhân của bạn, chúng tôi không thể đảm bảo an ninh tuyệt đối. Nếu bạn có bất kỳ câu hỏi về bảo mật trên trang web của chúng tôi, bạn có thể liên hệ với chúng tôi qua email.</p>
					</div>
					<div className="doc-para-item">
            <h2>4. Công bố</h2>
            <ol>
              <li><strong>IOHUB</strong> có thể sử dụng các nhà cung cấp dịch vụ bên thứ ba để cung cấp một số dịch vụ cho bạn và chúng tôi có thể chia sẻ thông tin cá nhân với các nhà cung cấp dịch vụ đó. Chúng tôi yêu cầu bất kỳ công ty nào mà chúng tôi có thể chia sẻ thông tin cá nhân bảo vệ dữ liệu một cách phù hợp với chính sách này và hạn chế việc sử dụng các thông tin cá nhân như vậy để thực hiện dịch vụ cho IOHUB.</li>
              <li>IOHUB có thể tiết lộ thông tin cá nhân trong các trường hợp đặc biệt, chẳng hạn như để thực hiện theo lệnh của tòa án yêu cầu chúng tôi làm như vậy hoặc khi hành động của bạn vi phạm Điều khoản Dịch vụ.</li>
              <li>Chúng tôi không bán hay cung cấp thông tin cá nhân cho các công ty khác để tiếp thị sản phẩm hay dịch vụ của mình.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>5. Lưu trữ dữ liệu khách hàng</h2>
            <ol>
              <li><strong>IOHUB</strong> sở hữu lưu trữ dữ liệu, cơ sở dữ liệu và tất cả các quyền đối với ứng dụng IOHUB. Tuy nhiên chúng tôi không yêu cầu sử dụng các quyền của dữ liệu của bạn. Bạn giữ lại tất cả các quyền đối với dữ liệu của bạn và chúng tôi sẽ không bao giờ liên lạc với khách hàng của bạn trực tiếp, hoặc sử dụng dữ liệu của bạn cho lợi thế kinh doanh của chúng tôi hoặc cạnh tranh với bạn hoặc thị trường để có khách hàng của bạn.</li>
              <li>Nếu bạn đóng tài khoản của mình, chúng tôi vẫn có thể giữ lại một số thông tin nhất định liên quan đến tài khoản của bạn nhằm mục đích phân tích và lưu giữ hồ sơ toàn vẹn, cũng như để tránh gian lận, thu thập bất kỳ khoản phí phải trả nào, thực thi các điều khoản và điều kiện của chúng tôi, của trang web của chúng tôi hoặc người dùng của chúng tôi, hoặc thực hiện các hành động khác nếu pháp luật cho phép.</li>
              <li>Ngoài ra, bạn sẽ được cung cấp một số quyền về việc lựa chọn bảo vệ dữ liệu của chính bạn:
                <ul>
                  <li>Nếu bạn muốn truy cập thông tin cá nhân của mình mà IOHUB thu thập, bạn có thể thực hiện việc này bất kỳ lúc nào bằng cách liên hệ với chúng tôi.</li>
                  <li>Bạn có thể xem xét, cập nhật, thay đổi thông tin trong hồ sơ đăng ký bằng cách đăng nhập vào tài khoản của bạn hoặc liên hệ với chúng tôi để thực hiện những việc này.
                    <p>Do sự phức tạp của công nghệ liên quan, chúng tôi không thể đảm bảo xóa toàn bộ hoặc không thể thu hồi thông tin tài khoản của bạn.</p>
                    <p>Nếu bạn có bất kỳ yêu cầu về xóa dữ liệu trên cơ sở dữ liệu của chúng tôi, bạn có thể gửi email cho chúng tôi, IOHUB sẽ xử lý yêu cầu của bạn trong vòng 30 ngày kể từ khi xác nhận bằng email.</p>								
                  </li>
                  <li>Bạn có thể phản đối việc xử lý thông tin cá nhân của mình, yêu cầu chúng tôi hạn chế xử lý thông tin cá nhân của bạn hoặc yêu cầu cung cấp thông tin cá nhân của bạn nếu có thể về mặt kỹ thuật. Một lần nữa, bạn có thể thực hiện các quyền này bằng cách liên hệ với chúng tôi.</li>
                </ul>
              </li>
              <li>Nếu thông tin nhất định đã được cung cấp cho các bên thứ ba như được mô tả trong Chính sách Bảo mật này, việc lưu giữ thông tin đó sẽ phải tuân theo các chính sách của bên thứ ba đó.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>6. Cookies</h2>
            <p>Cookie là một lượng nhỏ dữ liệu, có thể bao gồm một định danh duy nhất vô danh. Cookie được gửi tới trình duyệt của bạn từ một trang web và được lưu trữ trên ổ đĩa cứng của máy tính. Mỗi máy tính có thể truy cập trang web của chúng tôi được phân công một cookie khác nhau.</p>
					</div>
					<div className="doc-para-item">
            <h2>7. Sự riêng tư của trẻ em</h2>
            <p>Đây là một trang web dành cho đối tượng chung và không cung cấp các dịch vụ dành cho trẻ em. Nếu một cá nhân mà chúng tôi biết là dưới 18 tuổi gửi thông tin cá nhân cho chúng tôi, chúng tôi sẽ chỉ sử dụng thông tin đó để trả lời trực tiếp cho cá nhân đó để thông báo cho họ rằng chúng tôi phải có sự đồng ý của cha mẹ trước khi nhận được thông tin cá nhân của họ.</p>
					</div>
					<div className="doc-para-item">
            <h2>8. Quy định bảo vệ dữ liệu chung của EU</h2>
            <p>IOHUB hoạt động chủ yếu ở Việt Nam. Hiện nay Việt Nam vẫn chưa được Ủy ban Châu Âu công nhận là cung cấp sự bảo vệ đầy đủ. Chúng tôi hiện đang làm việc với một nhóm chuyên gia dành riêng để thực hiện bất kỳ điều chỉnh bắt buộc nào đối với sản phẩm, dịch vụ và tài liệu của chúng tôi, để đảm bảo tuân thủ luật Châu Âu (nếu có). Do đó, chúng tôi sẽ cố gắng tăng cường khả năng bảo vệ quyền riêng tư và quyền của bạn với việc tham chiếu tới Quy định chung về bảo vệ dữ liệu của Liên minh Châu Âu (EU-GDPR). Thông tin thêm về điều này có tại <u>https://www.gdpreu.org</u>.</p>
					</div>
					<div className="doc-para-item">
            <h2>9. Thay đổi Chính sách Bảo mật</h2>
            <p>Chúng tôi có quyền thay đổi Chính sách Bảo mật này bất cứ lúc nào, vì vậy hãy xem xét nó thường xuyên. Nếu chúng tôi có thay đổi quan trọng đối với chính sách này, chúng tôi sẽ thông báo cho bạn tại đây hoặc bằng một thông báo trên trang chủ của chúng tôi để bạn nhận thức được chúng tôi thu thập những thông tin nào, chúng tôi sử dụng chúng như thế nào, và chúng tôi sẽ tiết lộ chúng trong hoàn cảnh nào, nếu có.</p>
					</div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicyVI;
